//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import scrollTop from '@/lib/common-service/scrollTop'
import list from '@/lib/data-service/flight/fligthInternationalAdmin/flight_inter_buyer_manage_order_findVoidOrderList.js'
export default {
  data() {
    return {
      searchForm: {
        currentPage:1,
        pageSize:10
      },
      totalCount:0,
      flightAirlines: [],
      changeRecordList:[
        {text:'全部',value:''},
        {text:'处理中',value:61},
        {text:'已作废',value:62},
        {text:'废票失败',value:63},
      ],
      NavItemActive:'',
      pageData:[],
      loading:false,
    };
  },
  created(){
    this.search()
  },
  methods: {
    statuSearch(item,index){
       this.NavItemActive = index
       this.searchForm.invStatus = item.value
       this.search()
    },
    searchClear() {

    },
    // 日期选择
    selectPickerOptions() {
      return {
        disabledDate: time => {
            return time.getTime() > Date.now()
        }
      }
    },
    reset() {
      this.searchForm = {currentPage:1,pageSize:10}
    },
    search(pageNum) {
      let data = this.searchForm
      if(typeof pageNum == 'number'){
          data.currentPage = pageNum||1
      }else{
        data.currentPage = 1
      }
      data.startInvDate?data.endInvDate = this.$moment().format('YYYY-MM-DD'):data.endInvDate = ''
      list(data).then(res=>{
        this.pageData = res.pageResult.pageData
        this.totalCount = res.pageResult.totalCount
        scrollTop(500)
      })
    },
    setOrdersNo(item,type){
      console.log(item)
      this.$router.push({name:'air-ticket-admin-international-list-order-detail',
             query:{ordersNo:item.invalidId,type:type},params:{refresh:1}})
    },

    handlerSize(val){
      this.searchForm.pageSize = val
      this.search()
    },
    handlerPage(val){
      // this.searchForm.currentPage = val
      this.search(val)
    }
  }
};
